import { configureStore, combineReducers } from '@reduxjs/toolkit';
import lang from './reducers/LangReducer';

const reducer = combineReducers({
  lang
});

const thunk =
  ({ dispatch, getState }) =>
  (next) =>
  (action) =>
    typeof action === 'function' ? action(dispatch, getState) : next(action);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export default store;


