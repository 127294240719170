import React from "react";
import { useLocale } from "../utils/useLocale";

function NowSet() {
  const [locale] = useLocale();
  return (
    <div className="flex flex-col md:flex-row justify-center gap-20 items-center mb-16 mt-14 h-auto bg-slate-100 p-6 md:p-14 md:rounded-xl md:shadow-xl">
      <div className="flex flex-col md:flex-row gap-5 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-thumb-up"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
        </svg>
        <div className="text-center md:text-left">
          <p className="font-bold text-indigo-700">{locale.s1}</p>
          <p className="text-sm">{locale.s2}</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5 items-center mt-5 md:mt-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-shield-check"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M11.46 20.846a12 12 0 0 1 -7.96 -14.846a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.09 7.06" />
          <path d="M15 19l2 2l4 -4" />
        </svg>
        <div className="text-center md:text-left">
          <p className="font-bold text-indigo-700">{locale.s3}</p>
          <p className="text-sm">{locale.s4}</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5 items-center mt-5 md:mt-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-building-bank"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000000"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 21l18 0" />
          <path d="M3 10l18 0" />
          <path d="M5 6l7 -3l7 3" />
          <path d="M4 10l0 11" />
          <path d="M20 10l0 11" />
          <path d="M8 14l0 3" />
          <path d="M12 14l0 3" />
          <path d="M16 14l0 3" />
        </svg>
        <div className="text-center md:text-left">
          <p className="font-bold text-indigo-700">{locale.s5}</p>
          <p className="text-sm">
          {locale.s10}
          </p>
        </div>
      </div>
      
    </div>
  );
}

export default NowSet;
