import React, { useState, useEffect } from "react";
import SvgLogo from "./SvgLogo";
import NewsBar from "./NewsBar";
import Swipper from "./swiper/Swipper";
import Infarmatsiya from "./Infarmatsiya";
import NowSet from "./NowSet";
import FlashScreen from "./FlashScreen ";
import Fotoalobom from "./Fotoalobom";



function Navbar() {
  const [showFlashScreen, setShowFlashScreen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFlashScreen(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {showFlashScreen ? (
        <FlashScreen />
      ) : (
        <div className="">
          <SvgLogo />
          <Swipper />
          <NowSet />
          <NewsBar />
          <Infarmatsiya />
          <Fotoalobom/>
        </div>
      )}
    </>
  );
}

export default Navbar;
