import Footer from "./componets/Footer";
import Navbar from "./componets/Navbar";


function App() {
  return (
    <div className="App">
      <Navbar />
      <Footer />
    </div>
  );
}

export default App;
