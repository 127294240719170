import { useDispatch } from "react-redux";
import React from "react";
import logo from "../icon/Безымянный-1_Монтажная область 1.svg";
import { useLocale } from "../utils/useLocale";
import { langSelect } from "../redux/actions/LangActions";

function SvgLogo() {
  const [locale] = useLocale();
  const dispatch = useDispatch();

  const LangChange = (lng) => {
    dispatch(langSelect(lng.target.value));
  };
  return (
    <div className="bg-[#bdd6f8] flex flex-col md:flex-row h-auto items-center justify-between px-4 md:px-14">
      <button className="h-auto font-bold">
        <a className="flex gap-2 mb-3 mt-2" href="http://easytrip1.uz">
          <img src={logo} alt="" className="w-46 h-12" />
        </a>
      </button>

      <div className="mt-3 md:mt-0 md:flex flex-wrap gap-16 text-black font-bold">
        <p className="hover:text-indigo-700">
          <a href="http://">{locale.News}</a>
        </p>
        <select className="bg-[#bdd6f8] w-full md:w-[110px] mb-2 md:mb-0">
          <option value="Agencies">{locale.Agencies}</option>
          <option value="Cooperation">{locale.cooperation}</option>
          <option value="Documentation">{locale.Documentation}</option>
          <option value="Franchising">{locale.Franchising}</option>
        </select>
        <select className="bg-[#bdd6f8] w-full md:w-[140px] mb-2 md:mb-0">
          <option value="Клиентам">{locale.For_clients}</option>
          <option value="Flights">{locale.Flights}</option>
          <option value="Corporate culture">{locale.Corporate_culture}</option>
          <option value="Hotels">{locale.hotels}</option>
        </select>
        <select className="bg-[#bdd6f8] w-full md:w-[170px] mb-2 md:mb-0 text-[#000]">
          <option value="About company">{locale.About_company}</option>
          <option value="Как добраться">{locale.How_to_get_there}</option>
          <option value="Against corruption">
            {locale.Against_corruption}
          </option>
        </select>
      </div>
      <div className="flex gap-3 items-center bg-[#bdd6f8] mb-4 lg:mb-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-world"
          width="25"
          height="25"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#1363BC"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
          <path d="M3.6 9h16.8" />
          <path d="M3.6 15h16.8" />
          <path d="M11.5 3a17 17 0 0 0 0 18" />
          <path d="M12.5 3a17 17 0 0 1 0 18" />
        </svg>

        <select className="bg-[#bdd6f8] text-black" onChange={LangChange}>
          <option value="uz">Uzb</option>
          <option value="ru">Рус</option>
          <option value="en">Eng</option>
        </select>
      </div>
    </div>
  );
}

export default SvgLogo;
