import React from "react";
import { useLocale } from "../utils/useLocale";

function Infarmatsiya() {
  const [locale] = useLocale();
  return (
    <div>
      <div className="w-auto bg-slate-100 drop-shadow-xl mb-10 py-10 px-10">
        <div className="flex gap-4 items-center justify-center mt-8 mb-8 font-serif text-3xl">
          <p className="p-5">{locale.WHY_IS}</p>
        </div>
        <div className="flex flex-col gap-14 items-center justify-center lg:flex-row">
          <div className=" w-full ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-color-swatch"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2" />
                <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l9 9" />
                <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12" />
                <path d="M17 17l0 .01" />
              </svg>
            </div>
            <hr className="mt-5" />
            <div className=" font-mono">{locale.YOUR_CHOICE}</div>
            <p className="text-[#b8c3d1]">{locale.We_find}</p>
          </div>

          <div className=" w-full">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-cash-off"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M13 9h6a2 2 0 0 1 2 2v6m-2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />
                <path d="M12.582 12.59a2 2 0 0 0 2.83 2.826" />
                <path d="M17 9v-2a2 2 0 0 0 -2 -2h-6m-4 0a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                <path d="M3 3l18 18" />
              </svg>
            </div>
            <hr className="mt-5" />
            <div className=" font-mono">{locale.NO_EXTRAS}</div>
            <div className="text-[#b8c3d1]">{locale.We_do}</div>
          </div>

          <div className=" w-full">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-star"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
              </svg>
            </div>
            <hr className="mt-5" />
            <div className=" font-mono">{locale.SPECIES}</div>
            <div className="text-[#b8c3d1]">
              {locale.We_have_partner_companies_and_your_large_database}
            </div>
          </div>

          <div className=" w-full">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-medal-2"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#000000"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 3h6l3 7l-6 2l-6 -2z" />
                <path d="M12 12l-3 -9" />
                <path d="M15 11l-3 -8" />
                <path d="M12 19.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z" />
              </svg>
            </div>
            <hr className="mt-5" />
            <div className=" font-mono">{locale.tRUST}</div>
            <div className="text-[#b8c3d1]">{locale.Our_list}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infarmatsiya;
