import React from "react";
import Slider from "react-slick";
import { useLocale } from "../utils/useLocale";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css"; // Import your global CSS file

function Fotoalobom() {
  const [locale] = useLocale();
  const cards = [
    {
      image: "https://lh5.googleusercontent.com/p/AF1QipPh0HcRZQgDcMRt3DwCIG3Cx5hu_nhbTPflOf-b=w540-h312-n-k-no",
    },
    {
      image: "https://lh5.googleusercontent.com/p/AF1QipNa4pZp_eUgzt7305Q7jf7KNPtO-fqAPpOlDgn6=w540-h312-n-k-no",
    },
    {
      image: "https://lh6.googleusercontent.com/proxy/VSDBMeOlHFia1rVgjtrzdD42kC-HsycxOoAOJ_VCsdrf1eGyS8-438Q3d5ZNb2nr8rNFxLRn6010qqIibO88yCK8tSWGlI9c4nZ4SpC3OuHk_771MhIgkGMUPNdhXZ3ytMcfVc54246Fipdt8_4UvcyhIAcAkKg=w540-h312-n-k-no",
    },
    {
      image: "https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcSnhO7mUfwk7TgLY--mWDG_tPngT9XvvEQC_QqTw_3WyAauWEGetbs6LV1qJx1rxVREnkEnNhAEnAUYG-BI9Gb08i97VjFcTrcee40vsQ",
    },
    {
      image: "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcRuECgER8xnnzZdt2u-WcOjDSNjE-vQaD4kqG-hv8-o02KXWmDBg4F5VQqMQ_MaZ6F2_oOrwYEqamWo9Oxo8Oe5DliszUynWrmtjMfRUQ",
    },
    {
      image: "https://encrypted-tbn1.gstatic.com/licensed-image?q=tbn:ANd9GcQUbUsIYPrU2O_8-hkqp_bCfofVpH8BKlm1uAcROXJzkrCLwYKbMP7cDVLF4ghaawsuCmWrzNLrhwi5pmETtoUIkdwL8-beDtSGwjYc-A",
    },
    {
      image: "https://lh5.googleusercontent.com/p/AF1QipN6ygQQgKzC8B8wXHgME4HK2GeNG8pfH5KWVqHR=w540-h312-n-k-no",
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcS14UBWdJpyKLlJgOdkfcWuNtcMLOyaKvn_3ToNN-60OF6FY3PsFVITrBQF4zDn25eGXzGbkpY2sxLvows2xRsdmN3l8vqhxaak01CFXg",
    },
    {
      image: "https://lh5.googleusercontent.com/p/AF1QipO3FWtD3zMyKA5JZC2xvxO2K1Z3VoiS3f2rZMMn=w540-h312-n-k-no",
    },
    {
      image: "https://lh5.googleusercontent.com/p/AF1QipNxv6mp1wE-9826gCXFWCJyhXnVj57CU2BY-A4Z=w540-h312-n-k-no",
    },
    {
      image: "https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcTY8Irjwbk9klx03lkZJTqnDGOxiA8xYCX9j0gZz9oIXEPxhkGJ3OrTane_a0QVa_wZIbxvFqVkJiPVxEHF7EaqOyyH0oMfGBib4QeZzg",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000, // Increased speed for smoother transition
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Decreased autoplay speed for continuous motion
    pauseOnHover: true,
    cssEase: "linear", // Linear easing for constant speed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div>
      <div className="flex items-center justify-center">
        <p className="font-bold text-2xl">{locale.WHY_IS1}</p>
      </div>
      <div className="p-3">
        <Slider {...settings}>
          {cards.map((card, index) => (
            <div key={index} className="p-2">
              <div className="flex justify-center items-center">
                <img
                  src={card.image}
                  alt=""
                  className="rounded-l-md rounded-r-md animate-float"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Fotoalobom;