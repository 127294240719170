import { useMemo } from "react";
import { GetUserLang } from "../redux/selectors";
import localeLang from "../lang/localLang.json";

export const useLocale = () => {
  const lang = GetUserLang();
  console.log('====================================');
  console.log(lang);
  console.log('====================================');
  const locale = useMemo(() => {
    return localeLang[lang];
  }, [lang]);
  return [locale, lang];
};
