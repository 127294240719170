import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocale } from "../../utils/useLocale";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./swipper.css";



function Swipper() {
  const [locale] = useLocale();

  return (
    <div className="Slide mt-5 mb-14 ">
      <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
        
          <SwiperSlide>
            <div className="Slide1 text-white font-bold text-5xl   items-center justify-center flex">
              <h1>{locale.britain}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide2 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.türkiye}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide11 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.arab}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide3 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.Japan}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide4 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.russia}</h1>
            </div>
          </SwiperSlide>
          
          <SwiperSlide>
            <div className="Slide5 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.uzbekistan}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide6 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.Lithuania}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide7 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.Kazakhstan}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide8 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.Australia}</h1>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="Slide9 text-white font-bold text-5xl  items-center justify-center flex">
              <h1>{locale.Poland}</h1>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>
    </div>
  );
}

export default Swipper;
