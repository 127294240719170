import React, { useState, useEffect } from "react";
import { useLocale } from "../utils/useLocale";

function NewsBar() {
  const [locale] = useLocale();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenBuy, setModalOpenBuy] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleButtonClick = (card) => {
    setSelectedCard(card);
    setModalOpen(true);
  };
  const handleButtonBuyClick = (card) => {
    setSelectedCard(card);
    setModalOpenBuy(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCard(null);
  };
  const handleCloseBuyModal = () => {
    setModalOpenBuy(false);
    setSelectedCard(null);
  };

  useEffect(() => {
    if (modalOpen || modalOpenBuy) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalOpen, modalOpenBuy]);

  const cards = [
    {
      image:
        "https://www.visafirst.com/wp-content/uploads/2019/10/russia_top.jpg",
      location: locale.russia,
      price: "1.2700.000,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://lh3.googleusercontent.com/proxy/g-ljxmC9hqYpgbNRM2dhT4x30UsLIeylGkNR8j7xU75F3uXUHTB-hNcdjNy_H5_ZxgnbUxNUvne8TOZBcYT-BwoIQZgXwXlkyciXBHBPkNH2SavG7WhWDqT383O5PPPhie3Pw0fSRriv3TF8TEb8cu7JuH5-_Q=w675-h390-n-k-no",
      location: locale.america,
      price: "5.930.00,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcTFRr84OL7wCfGBTCFbsrgqiYZBY_gm-oLvmQKUtMe2s_KMlWiRAUNFMpNt8MowrUbVy8MJgAf6G955ft0S1sizjaiqxR80qLL8rmwTMUk",
      location: locale.bangkok,
      price: "2.930.00,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcTGePl1RDrYGlE1MK4YveJEAWbxlAF8Rau4UpC9FtnDjDbL9pdNX0ReQz0NR5-Ex4u0fKFGrhORnU3jfq0TQB0OGMung2zd72hutchU0A",
      location: locale.china,
      price: "2.258.00,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://lh5.googleusercontent.com/p/AF1QipMHftgSCBlvyjxYphi4gLqDC_62WWvZvyy1EBuh=w540-h312-n-k-no",
      location: locale.Avstrtaliya,
      price: "6.8.00,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://lh5.googleusercontent.com/p/AF1QipP75Zob4Z17aqG--OlvCWmdQapslYBH0ZHOZanL=w540-h312-n-k-no",
      location: locale.Great_Britain,
      price: "26.800,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://assets-global.website-files.com/62b1e3d97259065857ed5eef/62b1e3d972590670a3ed613b_Saudi%20Release.jpg",
      location: locale.arab,
      price: "10.800,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
    {
      image:
        "https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcSj7WYHei5ZvWq2gx4Lc2JAeNxZbX6F19AtJsqbdD5KcRRpsj5klnzHsm3Fvz5Xyz8lz5cwwksMpoVlA_1xrevRlro8t3W1Kj31qWVGTA",
      location: locale.Litva,
      price: "8.455.00,00 UZS",
      description:
        "Mehmonxonada yashash, Parvoz, Nonushta (mehmonxonada), Transfer, Sug'urta",
    },
  ];

  return (
    <div className="justify-center items-center flex-col mx-auto w-full">
      <div className="flex gap-4 items-center justify-center mt-8 mb-8 font-serif text-5xl text-center">
        <p>{locale.pOPULAR}</p>
      </div>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 items-center justify-center">
          {cards.map((card, index) => (
            <div
              key={index}
              className="flex gap-4 items-center mt-4 mb-4 justify-center"
            >
              <div className="bg-[#fff] rounded-l-md rounded-r-md w-[300px] h-[440px] border-solid border-black drop-shadow-md">
                <div className="img">
                  <img
                    src={card.image}
                    alt={card.location}
                    className="rounded-l-md rounded-r-md"
                  />
                </div>
                <div className="mt-3">
                  <p className="flex items-center justify-center text-xs">
                    {locale.uzbekistan}
                  </p>
                  <p className="flex items-center justify-center text-xl font-bold">
                    {card.location}
                  </p>
                </div>
                <div className="flex items-center justify-center mt-4">
                  <p className="text-sky-600 text-2xl font-medium">
                    {card.price}
                  </p>
                </div>
                <div className="flex justify-center items-center mt-6">
                  <button
                    className="rounded-s-lg rounded-r-lg w-[240px] h-[40px] bg-[#007AFF]"
                    onClick={() => handleButtonClick(card)}
                  >
                    <p className="text-white justify-center items-center text-center font-bold">
                      Harid qilish
                    </p>
                  </button>
                </div>
                <div className="flex justify-center items-center mt-6">
                  <button
                    className="rounded-s-lg rounded-r-lg w-[240px] h-[40px] bg-[#007AFF]"
                    onClick={() => handleButtonBuyClick(card)}
                  >
                    <p className="text-white justify-center items-center text-center font-bold">
                      Ma`lumot olish
                    </p>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {modalOpen && selectedCard && (
          <div className="fixed inset-0 flex items-center justify-center bg-[#ffffff] bg-opacity-50 drop-shadow-xl min-h-screen z-50">
            <div className="relative bg-[#fff] w-96 p-8 rounded-lg shadow-lg modal-open">
              <span
                className="absolute top-2 right-2 cursor-pointer bg-[#e8e8e8] rounded-full"
                onClick={handleCloseModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </span>
              <div>
                <h2 className="text-2xl font-bold mb-2 text-center">
                  {locale.malumot}
                </h2>
              </div>

              <div className=" items-center justify-center">
                <div className="mt-3">
                  <label class="block">
                    <span class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                      F.I.O
                    </span>
                    <input
                      type="email"
                      name="email"
                      class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Name..."
                    />
                  </label>
                </div>

                <div className="mt-3">
                  <label class="block">
                    <span class=" block text-sm font-medium text-slate-700">
                      Telefon
                    </span>
                    <input
                      type="Number"
                      name="Number"
                      class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="Number"
                    />
                  </label>
                </div>

                <div className="mt-3">
                  <label class="block">
                    <span class=" block text-sm font-medium text-slate-700">
                      Email
                    </span>
                    <input
                      type="email"
                      name="email"
                      class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                      placeholder="you@example.com"
                    />
                  </label>
                </div>

                <div className="mt-3">
                  <label class="block">
                    <span class="block text-sm font-medium text-slate-700">
                      Murojatingiz
                    </span>
                    <input
                      type="email"
                      name="email"
                      class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 h-28"
                    />
                  </label>
                </div>
                <div className="flex items-center justify-center mt-6">
                  <button className="rounded-s-lg rounded-r-lg w-[240px] h-[40px] bg-[#007AFF]"
                  onClick={handleCloseBuyModal}
                  >
                    <p className="text-white justify-center items-center text-center font-bold">
                      Rasmiylashtirish
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {modalOpenBuy && selectedCard && (
          <div className="fixed inset-0 flex items-center justify-center bg-[#ffffff] bg-opacity-50">
            <div className="bg-[#fff] p-8 rounded-md shadow-md max-w-md mx-auto">
              <div>
                <img
                  src={selectedCard.image}
                  alt={selectedCard.location}
                  className="w-full h-auto mb-4 rounded-md"
                />
              </div>
              <h2 className="text-xl font-bold mb-2">
                {selectedCard.location}
              </h2>
              <p className="text-sky-600 text-2xl font-medium">
                {selectedCard.price}
              </p>
              <p className=" text-orange-700">{selectedCard.description}</p>
              <div className="mt-4">
                <button
                  className="bg-[#007AFF] text-white px-4 py-2 rounded-md"
                  onClick={handleCloseBuyModal}
                >
                  Ortga
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewsBar;
