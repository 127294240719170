import { createReducer } from '@reduxjs/toolkit';

import { langSelect } from '../../actions/LangActions';

const initialState = 'uz';

const selectLanguage = createReducer(initialState, (builder) => {
  builder
    .addCase(langSelect, (state, action) =>
      state = action.payload,
    )
});

export default selectLanguage;
