import React from "react";
import logo from "../icon/Безымянный-1_Монтажная область 1.svg";

import { useLocale } from "../utils/useLocale";

function Footer() {
  const [locale] = useLocale();

  return (
    <div className="items-center justify-center mt-7 bg-[#bdd6f8]">
      <div className="h-auto font-bold flex gap-14 items-center justify-center px-10">
        <button className="h-auto font-bold">
          <a className="flex gap-2 mb-3 mt-2" href="http://easytrip1.uz">
            <img src={logo} alt="" className="w-46 h-12" />
          </a>
        </button>
        <div className="w-[300px] mt-3">
          <a href="https://www.turkishairlines.com/en-int/index.html?gad_source=1&gclid=CjwKCAiA75itBhA6EiwAkho9e0wBKt8nxnA__-Ws0y7WLfNkXPIU9NUB7SpSz2VYYfUrFS8MnXUK0hoC0lUQAvD_BwE">
            <img
              src="https://cdn.turkishairlines.com/m/2190da9e53a70e4e/original/THY-LOGO.png"
              alt=""
            />
          </a>
        </div>
        <div className="w-[100px]">
          <a href="https://www.iata.org/">
            <img src="https://www.svgrepo.com/show/306218/iata.svg" alt="" />
          </a>
        </div>
        <div className="w-[300px] mt-3">
          <a href="https://www.uzairways.com/">
            <img
              src="//upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Uzbekistan_Airways_logo.svg/255px-Uzbekistan_Airways_logo.svg.png"
              alt=""
            />
          </a>
        </div>
      </div>
      <div className=" border-solid w-full h-[1.5px]  bg-[#1363BC] items-center justify-center"></div>
      <div className="bg-[#bdd6f8] flex flex-col md:flex-row h-auto mx-auto justify-center lg:gap-36  md:px-14 mt-4 px-4 ">
        <div>
          <div className="flex gap-4 mb-5">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-phone"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>{locale.hOTLINE}</p>
              <p>+(998 98) 222-22-55</p>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-plane"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>{locale.aIR_TICKETS}</p>
              <p>+(998 99) 366-55-55</p>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-visa"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M21 15l-1 -6l-2.5 6" />
                <path d="M9 15l1 -6" />
                <path d="M3 9h1v6h.5l2.5 -6" />
                <path d="M16 9.5a.5 .5 0 0 0 -.5 -.5h-.75c-.721 0 -1.337 .521 -1.455 1.233l-.09 .534a1.059 1.059 0 0 0 1.045 1.233a1.059 1.059 0 0 1 1.045 1.233l-.09 .534a1.476 1.476 0 0 1 -1.455 1.233h-.75a.5 .5 0 0 1 -.5 -.5" />
                <path d="M18 14h2.7" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>{locale.train_ticket}</p>
              <p>+(998 91) 366-55-55</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-4 mb-5">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-map-pin"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>{locale.tOURISM}</p>
              <p>+(998 91) 366-55-55</p>
            </div>
          </div>
          <div className="flex gap-4 mb-5">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mail"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>{locale.e_MAIL}</p>
              <p>easytrip.uz@gmail.com</p>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-home-2"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#1363BC"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                <path d="M10 12h4v4h-4z" />
              </svg>
            </div>
            <div className=" text-black font-bold ">
              <p>
                {locale.namangan} <br /> {locale.uzbekistan}
              </p>
            </div>
          </div>
        </div>

        <div className=" text-black mt-8 md:mt-0">
          <p className=" font-bold mb-3">{locale.cOOPERATION}</p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.beginning}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.education}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.affiliate_program}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.Promotions_for_partners}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.connection_rules}
          </p>
        </div>
        <div className=" text-black mt-8 md:mt-0">
          <p className=" font-bold mb-3">{locale.iNFORMATION}</p>
          <p className="text-[#424242] hover:text-indigo-700">{locale.About}</p>
          <p className="text-[#424242] hover:text-indigo-700">{locale.news}</p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.advertising}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.vacancies}
          </p>
          <p className="text-[#424242] hover:text-indigo-700">
            {locale.leave_feedback}
          </p>
        </div>
      </div>
      <div className="hover:text-indigo-700 flex gap-2 items-center justify-center mt-8">
        <a href="https://www.instagram.com/easytrip_uz">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-brand-instagram"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#1363BC"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            <path d="M16.5 7.5l0 .01" />
          </svg>
        </a>
        <a href="https://www.facebook.com/easytrip1.uz">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-brand-facebook"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#1363BC"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
          </svg>
        </a>
        <a href="https://t.me/easytrip_center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-brand-telegram"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#1363BC"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
          </svg>
        </a>
      </div>
      <div className="border-solid w-auto h-[1.5px] bg-[#1363BC] mt-5"></div>

      <div className="flex flex-col md:flex-row h-auto items-center justify-evenly px-4 md:px-14 mt-4">
        <div className=" text-black text-center">{locale.copying}</div>
      </div>
      <div className="flex flex-col md:flex-row h-auto items-center justify-center px-4 md:px-14 mt-6">
        <div className=" text-black mb-11">{locale.reserved}</div>
      </div>
    </div>
  );
}

export default Footer;
